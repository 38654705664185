<style lang="scss" scoped>
.line {
	margin: 0.3rem 0.3rem;
	height: 1px;
	background: $line_color;
}

.main_title {
	font-size: 0.3rem;
	color: $font_color_main;
	font-weight: bold;
}

.product_name {
	padding: 0.4rem 0.3rem;
	line-height: 0.3rem;
}

::v-deep .van-cell {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}

::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}

::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}

::v-deep .van-field__body input {
	color: $font_color_val;
}

::v-deep .no_link .van-icon-arrow {
	color: #fff;
}

.add_user_btn {
	margin: 0.5rem 0.3rem 0.4rem;
	height: 0.9rem;
	border: 1px dashed #dddddd;
	display: flex;
	align-items: center;
	justify-content: center;

	.icon {
		width: 0.23rem;
		height: 0.22rem;
		background: url(../../../assets/icon/add.png);
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center;
	}

	.text {
		font-size: 0.3rem;
		color: $color_main;
		margin-left: 0.2rem;
	}
}

::v-deep .main_row {
	margin-top: 0.4rem;
	margin-bottom: 0.4rem;

	.cell_title,
	.cell_value {
		color: $font_color_thd;
		font-size: 0.24rem;
	}
}

::v-deep .checkbox {
	margin: 0.5rem 0.3rem;
	align-items: flex-start;

	.van-checkbox__icon {
		position: relative;
		top: 0.06rem;
		border-radius: 0.06rem;

		.van-icon {
			border-radius: 0.06rem;
		}

		padding-right: 0.2rem;
	}

	.van-checkbox__label {
		font-size: 0.24rem;
		line-height: 0.36rem;
		color: $font_color_thd;
		margin-left: 0;
	}
}

.is_link {
	color: $color_main;
}

.user_info_cell {
	align-items: center;
}

.user_icon {
	width: 0.28rem;
	height: 0.28rem;
	background: url(../../../assets/icon/delete.png);
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
	margin-right: 0.16rem;
}

.user_icon,
.user_title {
	font-size: 0.28rem;
}

::v-deep .must_read_pop {
	.van-action-sheet__content {
		overflow: hidden;
	}
}

.must_read_content {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 0 0.3rem 0.3rem;
	height: 70vh;
	box-sizing: border-box;

	.tabs {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.5rem;

		.tab {
			display: flex;
			flex-direction: column;
			align-items: center;

			.text {
				font-size: 0.28rem;
				line-height: 0.36rem;
				color: $font_color_val;
			}

			.icon {
				width: 0.3rem;
				height: 0.06rem;
				margin-top: 0.16rem;
			}
		}

		.active {
			.text {
				color: $color_main;
			}

			.icon {
				background-color: $color_main;
			}
		}
	}

	.content {
		flex: 1;
		overflow-y: auto;
	}

	.loading_btn_box {
		width: 100%;
		padding: 0.2rem 0.3rem;
		box-sizing: border-box;
		background-color: #fff;

		.loading_btn {
			border-radius: 8px;
			height: 0.8rem;
		}
	}

	.content_info {
		word-break: break-all;
	}
}

.must_read_pop {
	z-index: 3001 !important;

	.loading_btn_content {
		display: flex;
		align-items: center;

		.time {
			color: #fff;
			margin-left: 0.4rem;
		}
	}
}

::v-deep .protect_user_title .text {
	display: block;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;

	.user_same {
		padding: 0 0.2rem;
		font-size: 0.24rem;
		line-height: 0.5rem;
		text-align: center;
		border-radius: 0.08rem;
		border: 1px solid $color_main;
		color: #fff;
		background-color: $color_main;
	}

	.btns {
		display: flex;
		justify-content: space-between;

		.name_paste {
			margin-right: 0.2rem;
		}
	}
}

.per_rule {
	margin-left: 0.2rem;
}

// TODO:css
::v-deep .name_paste_dialog {
	z-index: 3002 !important;

	.name_textarea {
		textarea {
			max-height: 50vh;
			overflow-y: auto;
			// background-color: $line_color;
			border: 1px solid $line_color;
			padding: 0.08rem;
		}
	}

	.van-cell::after {
		border: none;
	}

	.btns {
		display: flex;
		justify-content: space-around;
		padding-bottom: 10px;
		padding: 0 0.3rem 10px;

		button {
			width: 42%;
		}
	}

	.van-dialog__cancel {
		color: #323233;
		background-color: #fff;
		background: #fff;
		border: 1px solid #ebedf0;
		border-radius: 2px;
		margin-right: 0.1rem;
	}

	.van-dialog__confirm {
		border-radius: 2px;
		margin-left: 0.1rem;
	}
}

::v-deep .van-overlay {
	z-index: 3001 !important;
}

::v-deep .analysis_toast {
	z-index: 3003 !important;
}

.error_name_dialog {
	.statistics {
		padding: 0.3rem;
		font-weight: bold;
		font-size: 14px;
	}

	.num {
		font-size: 20px;
	}

	.error_num {
		color: $danger;
	}

	.title {
		padding: 0 0.3rem;
		font-size: 14px;
		line-height: 14px;
		position: relative;
		top: 6px;
		z-index: 1;
	}

	.error {
		color: $danger;
		font-size: 12px;
		line-height: 14px;
		position: relative;
		top: -6px;
		padding: 0 0.3rem;
		margin-bottom: 0.2rem;
	}
}

.user_list_error_text {
	color: $danger;
}

.btn {
	box-sizing: border-box;
	position: absolute;
	right: 0px;
	margin-right: 0.4rem;
}

::v-deep {
	.van-picker__columns {
		.van-picker-column {
			&:nth-child(3) {
				display: none;
			}
		}
	}
}
</style>
<template>
	<div>
		<top-nav @back="back">创建保单</top-nav>
		<div class="main_title product_name">{{ productInfo.name }}--{{ comboInfoWay }}</div>
		<van-form ref="form" input-align="right" error-message-align="right" :scroll-to-error="true">
			<!-- 投保信息 -->
			<active-title>选择投保信息</active-title>
			<van-cell-group :border="false">
				<van-field label="保障期限" :value="proteceInfo.time" :border="false" readonly is-link clickable placeholder="请选择保障期限" @click="timePop = true" :rules="rules.time" />
				<van-field label="起保日期" :value="proteceInfo.start" :border="false" readonly is-link clickable placeholder="请选择起保日期" @click="startDatePop = true" :rules="rules.start" />
				<van-field label="终保日期" :value="proteceInfo.end" :border="false" readonly is-link clickable placeholder="请选择终保日期" @click="endDatePop = true" :rules="rules.end" />
			</van-cell-group>
			<div class="line"></div>

			<!-- 保险标地 -->
			<active-title>保险标地</active-title>
			<van-cell-group :border="false">
				<van-field :rules="rules.name" v-model="insureArea.name" label="活动名称" placeholder="活动名称" />
				<van-field :rules="rules.site" v-model="insureArea.site" is-link readonly label="施工地址" placeholder="施工地址" @click="cascaderShowbtn" />
				<van-field :rules="rules.address" v-model="insureArea.address" label="工程详细地址" placeholder="工程详细地址" />
			</van-cell-group>
			<div class="line"></div>

			<!-- 投保人信息 -->
			<active-title>投保人信息</active-title>
			<van-cell-group :border="false">
				<van-field label="企业名称" v-model="userInfo.userName" placeholder="请填写企业名称" :rules="rules.userName" />
				<van-field label="统一社会信用代码" v-model="userInfo.socialCode" placeholder="请填写统一社会信用代码" :rules="rules.socialCode" />
				<van-field label="投保人手机号" v-model="userInfo.phone" placeholder="请填写您的手机号" :rules="rules.phone" />
			</van-cell-group>
			<div class="line"></div>
		</van-form>
		<van-form ref="bbform" input-align="right" error-message-align="right" :scroll-to-error="true">
			<!-- 被保险人 -->
			<active-title class="protect_user_title">
				<span v-text="`被保人列表(${userList.length}/200)`"></span>
				<div class="btns">
					<div class="user_same" @click="userSame" v-if="userList.length === 0">同投保人</div>
				</div>
			</active-title>
			<van-cell-group :border="false" v-show="userList.length">
				<van-cell v-for="(item, index) in userList" :key="index" @click="editUser(item)" title-class="cell_title" value-class="cell_value" class="user_info_cell" :border="true" is-link>
					<span class="user_icon" slot="icon" @click.stop="deleteUser(item.id)"></span>
					<span class="user_title" slot="title" v-text="item.userName"></span>
					<span slot="default" class="user_list_error_text">
						<span v-if="item.isError">信息中有误</span>
						<span v-if="item.isError && item.isRepeat">，</span>
						<span v-if="item.isRepeat">证件号码重复</span>
					</span>
				</van-cell>
			</van-cell-group>
		</van-form>

		<div class="add_user_btn" @click="addUser">
			<span class="icon"></span>
			<span class="text">新增被保险人</span>
		</div>

		<van-checkbox class="checkbox" v-model="rule_1" shape="square" icon-size="0.28rem" label-disabled>
			<span @click="rule_1 = !rule_1">本人已充分理解并同意</span>
			<span class="is_link" @click="seeRules">《保险条款》</span>
			<span class="is_link" @click="seeRule('投保声明')">《投保声明》</span>
			<br />
			<span class="is_link per_rule" @click="seeRule('个人信息保护政策')">《个人信息保护政策》</span>
		</van-checkbox>
		<van-checkbox class="checkbox" v-model="rule_2" shape="square" icon-size="0.28rem" @click="rule_2_check">
			本人已充分理解并同意
			<span class="is_link">《免责内容》</span>
			<span class="is_link">《被保险人同意声明》</span>
			<br />
			<span class="is_link per_rule">《投保提示》</span>
		</van-checkbox>
		<price-buy-btn v-show="buyBtnShow" :price="price" @buy="buy"></price-buy-btn>

		<!-- 弹出层 -->
		<!-- 起保日期 -->
		<van-calendar v-model="startDatePop" @confirm="startDateCheck" :min-date="startDateMinDate" color="#2594EF" :formatter="$base.calendarFormatter" />
		<!-- 终保日期 -->
		<van-calendar v-model="endDatePop" @confirm="endDateCheck" :default-date="new Date(proteceInfo.end)" :min-date="endDateMinDate" :max-date="endDateMaxDate" color="#2594EF" :formatter="$base.calendarFormatter" />
		<!-- 保障期限 -->
		<van-popup v-model="timePop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="保障期限" show-toolbar value-key="view_time" :visible-item-count="3" :columns="timeList" @confirm="timeCheck" @cancel="timePop = false" />
		</van-popup>
		<!-- 施工地址 -->
		<van-popup v-model="cascaderShow" round position="bottom">
			<van-cascader v-model="cascaderValue" title="请选择施工地址" :fieldNames="fieldNames" :options="options" @close="cascaderShow = false" @change="onCascader" @finish="onFinish" />
		</van-popup>
		<bottom-info-pop v-model="infoPopShow" :title="infoPopTitle" :content="infoPopContent"></bottom-info-pop>
		<van-action-sheet v-model="mustReadPopShow" class="must_read_pop" title="请确认以下内容">
			<div class="must_read_content">
				<div class="tabs">
					<div class="tab" :class="{ active: mustReadIndex === 1 }">
						<span class="text">《免责内容》</span>
						<span class="icon"></span>
					</div>
					<div class="tab" :class="{ active: mustReadIndex === 2 }">
						<span class="text">《被保险人同意声明》</span>
						<span class="icon"></span>
					</div>
					<div class="tab" :class="{ active: mustReadIndex === 3 }">
						<span class="text">《投保提示》</span>
						<span class="icon"></span>
					</div>
				</div>
				<div class="content">
					<div class="content_info" v-show="mustReadIndex === 1" v-html="information.preventDuty">免责内容</div>
					<div class="content_info" v-show="mustReadIndex === 2" v-html="information.insuredDeclare">被保险人同意声明</div>
					<div class="content_info" v-show="mustReadIndex === 3" v-html="information.insuranceTip">投保提示</div>
				</div>
				<div class="loading_btn_box">
					<van-button class="loading_btn" @click="nextDoc" :disabled="isReading" type="info" color="#2594EF" block="" size="normal">
						<div class="loading_btn_content">
							<span v-show="mustReadIndex === 1">已阅读并同意《免责内容》</span>
							<span v-show="mustReadIndex === 2">已阅读并同意《被保险人同意声明》</span>
							<span v-show="mustReadIndex === 3">已阅读并同意《投保提示》</span>
							<van-count-down v-show="isReading" ref="countDown" class="time" :time="200" @finish="finish">
								<template #default="timeData">
									<span class="block">{{ timeData.seconds }}秒</span>
								</template>
							</van-count-down>
						</div>
					</van-button>
				</div>
			</div>
		</van-action-sheet>
	</div>
</template>
<script>
import { Search, Area, Cell, CellGroup, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, ActionSheet, Button, CountDown, Form, Toast, Dialog, Cascader } from 'vant';
import { http_getServerTime } from '@/request/common';
import { productDictInfo, fixedInfo, getPrice, submitBill } from '@/request/api';
import { http_city, http_province, http_county } from '@/request/proRegister';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';

export default {
	name: 'grCreateOrder',
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Checkbox.name]: Checkbox,
		[ActionSheet.name]: ActionSheet,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		[Form.name]: Form,
		[Toast.name]: Toast,
		[Dialog.Component.name]: Dialog.Component,
		[Area.name]: Area,
		[Search.name]: Search,
		[Picker.name]: Picker,
		[Cascader.name]: Cascader,
	},
	watch: {
		'$route': {
			handler: function (route) {
				let params = route.params;
				if (params.hasOwnProperty('productInfo')) {
					this.productInfo = params.productInfo;
					this.information = params.information;
				}

				if (params.hasOwnProperty('product')) {
					this.product = params.product;
					this.setStartDate();
				}
				if (params.hasOwnProperty('otherInfo')) {
					this.otherInfo = params.otherInfo;
				}

				if (params.hasOwnProperty('userInfo')) {
					this.setUserInfo(params.userInfo);

					// 执行校验方法
					this.chekUserInfoList();

					// 执行重复检查
					this.validateRepeat();

					// 刷新DOM
					this.$forceUpdate();
				}
				if (params.detailTransNo) {
					this.detailTransNo = params.detailTransNo;
				}
			},
			immediate: true,
		},

		// 价格计算
		'proteceInfo.time': function (val) {
			if (this.userList.length) {
				if (!this.product.priceIsRelatedToNumberInsur) {
					this.getPrice(1);
				} else {
					this.getPrice(this.userList.length);
				}
			}
		},
		'userList.length': function (val) {
			if (val) {
				// this.getPrice(val)
				if (!this.product.priceIsRelatedToNumberInsur) {
					this.getPrice(1);
				} else {
					this.getPrice(val);
				}
			}
		},
	},
	created() {
		this.getProductInfo();
		this.getRules();
	},
	beforeRouteLeave(to, from, next) {
		if (to.name === 'guoRen_index') {
			khs.stop();
			const store = from.matched[0].instances.default.$store;
			store.commit('delete_keepAlivePage', 'grCreateOrder');
		}
		this.scrollY = document.querySelector('html').scrollTop;
		next();
	},
	beforeRouteEnter(to, from, next) {
		if (from.name === 'guoRen_index') {
			const store = from.matched[0].instances.default.$store;
			store.commit('add_keepAlivePage', 'grCreateOrder');
		}
		next(vm => {
			vm.$nextTick(() => {
				document.querySelector('html').scrollTop = vm.scrollY;
			});
		});
	},
	data() {
		return {
			scrollY: 0,
			information: {},
			productInfo: {},
			product: {},
			otherInfo: {}, //扩展字段
			comboInfoWay: '', //计划方案
			timePop: false,
			insureArea: {
				name: '',
				site: '',
				address: '',
			},
			// 投保人信息
			userInfo: {
				userName: '',
				socialCode: '',
				phone: '',
			},
			userList: [], //被保人列表
			proName: '', //产品名字
			cityName: '', //城市名字
			cityCode: '', //城市编码
			countyName: '', //区县名字
			// 投保信息日历弹出层
			startDatePop: false,
			endDatePop: false,
			endDateMinDate: new Date(),
			endDateMaxDate: new Date(),
			addressShow: false,
			timeList: [],
			timePop: false,
			timeList: [],
			// 保障期限
			timeLimitDict: null,
			proteceInfo: {
				time: '',
				start: '',
				end: '',
			},
			rules: {
				time: [{ required: true }],
				start: [{ required: true }],
				site: [{ required: true }],
				address: [{ required: true }],
				userName: [{ required: true }],
				socialCode: [{ required: true }, { validator: this.cardNumCheck, message: '证件号码有误' }],
				phone: [{ required: true }, { validator: this.phoneCheck, message: '请填写正确的手机号码' }],
			},
			// 条款
			rule_1: false,
			rule_2: false,
			// 投保声明、个人信息保护政策内容
			rule_1_list: [],
			infoPopShow: false,
			infoPopTitle: '',
			infoPopContent: '',

			mustReadPopShow: false,
			mustReadIndex: 1,
			isReading: true,

			buyBtnShow: true,
			price: 0,

			startDateMinDate: new Date(),
			startSecond: '00:00:00',
			isNextSecondStart: false, // 是否即时起保

			fieldValue: '',
			cascaderValue: '',
			options: [],
			cascaderShow: false,
			fieldNames: {
				text: 'name',
				value: 'value',
				children: 'children',
			},
			index: null,
			cityIndex: null,
			postCode: '', //行政编码
		};
	},
	methods: {
		// 新增新增被保险人-打开页面
		addUser() {
			this.$router.push({
				name: 'grAddUser',
			});
		},
		// 新增被保险人-设置数据 idCompensation: id补偿字符，因为循环太快，在1ms中可以计算很多数据，id会重复
		setUserInfo(info, idCompensation = undefined) {
			if (!info.id) {
				info.id = String(Date.now());
				if (idCompensation !== undefined) {
					info.id += idCompensation;
				}
				this.userList.push(info);
			} else {
				this.userList.forEach(item => {
					if (item.id === info.id) {
						Object.assign(item, info);
					}
				});
			}
		},

		// 删除被保人
		deleteUser(id) {
			let ids = this.userList.map(item => item.id);
			let index = ids.indexOf(id);
			this.userList.splice(index, 1);

			// 执行重复检查
			this.validateRepeat();
		},

		// 编辑被保人信息
		editUser(info) {
			this.$router.push({
				name: 'grAddUser',
				params: {
					info,
				},
			});
		},

		// 手机号码校验
		phoneCheck(val) {
			return regular.phone.test(val);
		},
		// 证件类型校验方法
		cardNumCheck(val) {
			return Mtils.validation.isCreditCode(val);
		},

		// 同投保人
		userSame() {
			this.$refs.form.validate().then(
				() => {
					if (!this.userList.some(item => item.socialCode === this.userInfo.socialCode)) {
						this.userList.push(this.userInfo);
					}
				},
				() => {
					Toast('请先按要求把信息填写完整');
				},
			);
		},
		// 手动校验被保险人信息
		chekUserInfoList() {
			this.userList.forEach(dict => {
				dict.isError = !this.checkUserInfo(dict);
			});
		},

		// 校验被保险人信息方法，有错误即停止检查，并返回false。完全正确返回true
		checkUserInfo(info) {
			let result = true;
			outer: for (const key in info) {
				if (Object.hasOwnProperty.call(info, key) && Object.hasOwnProperty.call(this.rules, key)) {
					const val = info[key];
					let ruleList = this.rules[key];

					// 注意：break无法跳出foreach
					for (let i = 0; i < ruleList.length; i++) {
						const rule = ruleList[i];
						// 必填校验
						if (Object.hasOwnProperty.call(rule, 'required') && key !== 'phone') {
							if (rule.required && !val && val !== 0) {
								result = false;
								break outer;
							}
						}

						// 自定义方法校验
						if (Object.hasOwnProperty.call(rule, 'validator')) {
							if (rule.validator && (val || val === 0)) {
								if (rule.validator(val, true, info) !== true) {
									result = false;
									break outer;
								}
							}
						}
					}
				}
			}
			return result;
		},
		// 检查重复的证件号码，该方法回标记出所有的重复信息，比如：有三条一模一样的证件号码，会把这三条全标记出来
		validateRepeat(cb) {
			let repeatInfos = [];
			let idNumList = this.userList.map(row => row.socialCode);
			let repeatIds = [];
			for (let i = 0; i < idNumList.length; i++) {
				const num = idNumList[i];
				let otherNums = idNumList.slice(i + 1);
				let repeatIndex = otherNums.indexOf(num);
				if (repeatIndex !== -1) {
					repeatIds.push(num);
					this.userList[i].isRepeat = true;
					this.userList[i + repeatIndex + 1].isRepeat = true;
					repeatInfos.push(this.userList[i], this.userList[i + repeatIndex + 1]);
				} else {
					if (!repeatIds.includes(num)) {
						this.$set(this.userList[i], 'isRepeat', false);
					}
				}
			}
			if (cb) {
				cb(repeatInfos);
			}
		},

		back() {
			this.$store.commit('delete_keepAlivePage', 'grCreateOrder');
			this.$router.push({ name: 'guoRen_index', query: { user: this.$store.state.user, product: this.$store.state.productId } });
		},

		// 获取产品信息
		getProductInfo() {
			productDictInfo(this.$store.state.productId, this.$store.state.plainId).then(res => {
				this.timeList = res.insureTimeList;
				this.comboInfoWay = res.comboInfo.comboName;
				if (res.insureTimeList.length) {
					this.timeCheck(res.insureTimeList[0]);
				}
			});
		},
		setStartDate() {
			if (this.product.immediatelyFlag && this.product.immediatelyTimeLimit > new Date().getHours()) {
				//即时投保
				this.startDateMinDate = new Date();
			} else {
				this.startDateMinDate = new Date(Date.now() + 8.64e7);
			}
		},
		timeCheck(val) {
			this.timeLimitDict = val;
			this.proteceInfo.time = val.view_time;
			this.timePop = false;
			this.setEndDate();
		},
		// 获取投保声明、个人信息保护政策
		getRules() {
			fixedInfo().then(res => {
				this.rule_1_list = res.fixedInfo;
			});
		},

		// 去保障条款页面
		seeRules() {
			this.$router.push({
				name: 'safeguardClause',
				params: {
					productId: this.productInfo.id,
				},
			});
		},
		seeRule(title) {
			this.infoPopTitle = title;
			this.rule_1_list.forEach(dict => {
				if (dict.typeDesc === title) {
					this.infoPopContent = dict.fixedInfo;
				}
			});
			this.infoPopShow = true;
		},
		nextDoc() {
			if (this.mustReadIndex < 3) {
				this.mustReadIndex++;
				this.isReading = true;
				this.$refs.countDown.reset();
			} else {
				this.rule_2 = true;
				this.mustReadPopShow = false;
			}
		},
		rule_2_check() {
			if (this.rule_2) {
				this.rule_2 = false;
				this.mustReadIndex = 1;
				this.isReading = true;
				this.mustReadPopShow = true;
				this.$nextTick(() => {
					this.$refs.countDown.reset();
				});
			}
		},
		finish() {
			this.isReading = false;
		},
		// 计算价格
		getPrice(num) {
			let send = {
				comboId: this.$store.state.plainId,
				eleView1: '',
				eleView2: '',
				insureTime: this.timeLimitDict.insure_time,
				insureTimeUnit: this.timeLimitDict.insure_time_unit,
				priceId: this.timeLimitDict.price_id,
				proId: this.$store.state.productId,
				sex: '',
				viewAgeBelong: '',
				viewTime: this.timeLimitDict.view_time,
			};
			getPrice(send).then(res => {
				this.price = this.$base.floatPrice(Number(res.priceInfo.price) * num);
			});
		},
		buy() {
			if (!this.rule_1 || !this.rule_2) {
				Toast('请先勾选条款');
				return false;
			}
			this.$refs.form.validate().then(
				() => {
					if (this.userList.length) {
						if (this.isNextSecondStart) {
							// 即时起保，从服务器获取时间戳,防止用户客户端时间不准
							Toast.loading({
								duration: 0, // 持续展示 toast
								forbidClick: true,
								message: '提交订单中',
							});
							http_getServerTime().then(resTP => {
								Toast.clear();
								this.submitForm(Number(resTP.timestamp)).then(res => {
									khs.finish(res.id);
									this.$store.commit('set_billId', res.id);
									this.$router.push({
										name: 'orderPay',
										params: {
											isRedict: false,
										},
									});
								});
							});
						} else {
							this.submitForm().then(res => {
								khs.finish(res.id);
								this.$store.commit('set_billId', res.id);
								this.$router.push({
									name: 'orderPay',
									params: {
										isRedict: false,
									},
								});
							});
						}
					} else {
						Toast('请先添加被保人');
					}
				},
				() => {
					Toast('请先按要求把信息填写完整');
				},
			);
		},
		// 获取特殊标记字符串
		getSpecialStr() {
			let id = '';
			let userStr = localStorage.getItem('userInfo');
			if (userStr && userStr !== 'null' && userStr !== 'undefined') {
				let user = JSON.parse(userStr);
				if (user.id) {
					id = user.id;
				}
			}
			// userStr = userStr.split('=')[1].split('&')[0]
			return `$H5ZRX-$GUOREN-${id}-${Date.now()}`;
		},
		// 提交订单
		submitForm(timestamp = 0) {
			let assuredList = this.userList.map(item => {
				return {
					name: item.userName,
					certificateType: '3',
					certificateContent: item.socialCode,
				};
			});

			// 额外字段
			let extentionList = [];
			this.otherInfo.map(item => {
				if (item.fieldName === 'address') {
					extentionList.push({
						colName: 'address',
						colValue: this.insureArea.address,
					});
				} else if (item.fieldName === 'provinceName') {
					extentionList.push({
						colName: 'provinceName',
						colValue: this.proName,
					});
				} else if (item.fieldName === 'cityName') {
					extentionList.push({
						colName: 'cityName',
						colValue: this.cityName,
					});
				} else if (item.fieldName === 'districtCname') {
					extentionList.push({
						colName: 'districtCname',
						colValue: this.countyName,
					});
				}
			});
			if (this.insureArea.name) {
				extentionList.push({
					colName: 'projectName',
					colValue: this.insureArea.name,
				});
			}
			if (this.postCode) {
				extentionList.push({
					colName: 'postCode',
					colValue: this.postCode,
				});
			}
			let specialString = this.getSpecialStr();
			let send = {
				assuredList, // 被保人
				insurer: {
					insName: this.userInfo.userName,
					insPhone: this.userInfo.phone,
					insCredentials: this.userInfo.socialCode,
					insCredentialsType: '3',
				},
				extentionList,
				insBill: {
					enableDate: `${this.$base.submitDateFormatter(this.proteceInfo.start)} ${this.isNextSecondStart ? this.$base.getTime(timestamp + this.$store.state.immediateExtendTime * 1000 * 60) : this.startSecond}`,
					disEnableDate: this.$base.submitDateFormatter(this.proteceInfo.end, 2), //终保日期
					id: '',
					insureType: 2, //出单方式
					proId: this.$store.state.productId, //产品ID
					// "remark": this.insureArea.name,
					platform: 'H5',
					assuredSendMsg: 1,
					remark: specialString,
				},
				optType: '2',
				productPriceVo: {
					comboId: this.$store.state.plainId,
					proId: this.$store.state.productId,
					viewTime: this.timeLimitDict.view_time,
					eleView1: '',
					eleView2: '',
					priceId: this.timeLimitDict.price_id,
					sex: '',
					viewAgeBelong: '',
					insureTime: this.timeLimitDict.insure_time,
					insureTimeUnit: this.timeLimitDict.insure_time_unit,
				},
			};
			Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: '提交订单中',
			});
			return new Promise(resolve => {
				submitBill(send).then(
					res => {
						Toast.clear();
						Toast.success('订单提交成功');
						resolve(res.data);
					},
					err => {
						console.log(err);
					},
				);
			});
		},

		// 计算终保日期
		setEndDate() {
			if (this.proteceInfo.time && this.proteceInfo.start) {
				let startDate = new Date(this.proteceInfo.start);
				let timeDict = this.timeLimitDict;
				let endMs = startDate.getTime() + (Number(timeDict.totalDays) - 1) * 24 * 60 * 60 * 1000;
				this.proteceInfo.end = this.$base.dateFormater(new Date(endMs));
				this.endDateMaxDate = new Date(endMs);
				if (timeDict.view_time.includes('-')) {
					let minDaysStr = timeDict.view_time.split('-')[0];
					let minEndMs = startDate.getTime() + (Number(minDaysStr) - 1) * 24 * 60 * 60 * 1000;
					this.endDateMinDate = new Date(minEndMs);
				} else {
					this.endDateMinDate = new Date(endMs);
				}
			}
		},
		endDateCheck(val) {
			this.proteceInfo.end = this.$base.dateFormater(val);
			this.endDatePop = false;
		},
		startDateCheck(val) {
			if (this.product.immediatelyFlag && this.product.immediatelyTimeLimit > new Date().getHours()) {
				//即时投保
				let date = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
				this.startSecond = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':' + (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
				this.isNextSecondStart = true;
			} else {
				this.startSecond = '00:00:00';
				this.isNextSecondStart = false;
			}

			this.proteceInfo.start = this.$base.dateFormater(val);
			this.startDatePop = false;
			this.setEndDate();
		},

		getProCity() {
			this.getProvince();
			this.addressShow = true;
		},

		// 省份确认按钮
		areaSubmit(list) {
			this.proName = list[0].name;
			this.cityName = list[1].name;
			this.cityCode = list[1].code;
			this.insureArea.site = list
				.map((item, index) => {
					if (index < 2) {
						return item.name;
					}
				})
				.join(' ');
			this.countyName = '';
			this.searchVal = '';
			this.addressShow = false;
		},
		// 取消按钮
		areaCancel() {
			this.searchVal = '';
			this.addressShow = false;
		},

		// 改变省份方法
		changeProvince(picker, index) {
			this.getProvince(index[0].code);
		},

		// 获取省列表
		async getProvince() {
			const res = await http_province();
			// return
			if (this.options.length === 0) {
				res.data.forEach(item => {
					this.$set(item, 'children', []);
					this.options.push(item);
				});
			}
		},

		// 获取城市列表
		async getCityList(province) {
			const res = await http_city(province);
			return res.data;
		},

		// 获取区县列表
		async getCounty(cityCode) {
			const res = await http_county(cityCode, '3');
			return res.data;
		},

		// 施工地址选择方法
		async onCascader(e) {
			if (e.tabIndex === 0) {
				let result = await this.getCityList(e.value);
				this.options.some((item, i) => {
					if (item.value === e.value) {
						this.index = i;
						return true;
					}
				});
				result.forEach(item => {
					item.value += ` ${e.tabIndex}`;
					item.children = [];
				});
				this.$set(this.options[this.index], 'children', result);
			} else if (e.tabIndex === 1) {
				let countyCode = e.value.split(' ')[0];
				let resultCity = await this.getCounty(countyCode);
				if (this.index !== null) {
					this.options[this.index].children.some((v, i) => {
						if (v.value === e.value) {
							this.cityIndex = i;
							return true;
						}
					});
					this.$set(this.options[this.index].children[this.cityIndex], 'children', resultCity);
				}
			} else if (e.tabIndex === 2) {
				this.cascaderShow = false;
				this.insureArea.site = e.selectedOptions
					.map((item, index) => {
						if (index === 0) {
							this.proName = item.name;
						} else if (index === 1) {
							this.cityName = item.name;
						} else if (index === 2) {
							this.countyName = item.name;
							this.postCode = item.value;
						}
						return item.name;
					})
					.join(' ');
			}
		},

		onFinish(e) {
			// console.log(e, 'onFinish');
		},

		cascaderShowbtn() {
			this.cascaderShow = true;
			if (this.options.length === 0) {
				this.getProvince();
			}
		},
	},
};
</script>
