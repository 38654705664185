import { get, post, postParams } from './http';

// 获取行业标签
export function http_businessList() {
	return post('/insurance/api/common/loadIndustryLabelList');
}

// 获取场景标签
export function http_sceneList(businessCode) {
	return postParams('/insurance/api/common/loadIndustryLabelSubList', { industryLabelCode: businessCode });
}

// 获取省份
export function http_province() {
	return post('/insurance/api/insArea/loadProvinceInfo');
}

// 获取城市
export function http_city(provinceCode) {
	return postParams('/insurance/api/insArea/loadCityListByProvinceCode', { insProvince: provinceCode });
}
// 获取区县
export function http_county(parentCode, areaType) {
	return postParams(`/insurance/api/insArea/loadDistrictByProvinceCode`, { parentCode, areaType });
}

// 获取短信验证码
export function http_getMsgCode(data) {
	return post('/insurance/api/common/smsCode', data);
}

// 提交注册信息
export function http_register(data) {
	return post('/insurance/api/user/proRegister', data);
}
